import { useEffect, useState } from 'react';
import { SelectFormInput } from '../SelectFormInput';
import { SelectOptionsInterface2 as SelectOptionsInterface } from '../../../../../utils/interfaces/views/form/inputs/select';
import { GET_ClientGroup } from '../../../../../utils/network/client/group_subgroups/groups/get';
import { GroupGroupSubgroupViewModel, Convert as ggsConvert } from '../../../../../utils/interfaces/client/group_subgroup/groups';

export const GroupSelectFormInput = (properties: {
  label: string; name?: string; id?: string; required?: boolean;
  data?: Array<SelectOptionsInterface>, multiple?: boolean;
  selectedValues?: Array<SelectOptionsInterface>,
  setSelectedValues?: React.Dispatch<React.SetStateAction<Array<SelectOptionsInterface>>>,
  selectedBranchValues?: Array<SelectOptionsInterface>,
}) => {
  const [_selectedValues, _setSelectedValues] = useState<Array<SelectOptionsInterface>>([{label: "", value: "0"}])
  const label = properties.label,
    id = properties.id ?? "",
    name = properties.name ?? "",
    multiple = properties.multiple ?? false,
    required = properties.required ?? false,
    selectedValues = properties.selectedValues ?? _selectedValues,
    setSelectedValues = properties.setSelectedValues ?? _setSelectedValues,
    selectedBranchValues = properties.selectedBranchValues ?? null,
    data = properties.data ?? null;
  const [groups, setGenders] = useState<Array<GroupGroupSubgroupViewModel>>([]);
  const [dataFetched, setDataFetched] = useState<boolean>(false);
  const [selectedBranchId, setSelectedBranchId] = useState<number | null>(null);

  useEffect(() => {
    // console.log({ dataFetched, groups, selectedBranchValues });
    // if (data === null) {
    //   if (!dataFetched) {
    //     const branchId = Array.isArray(selectedBranchValues) && (selectedBranchValues.length > 0)
    //       ? Number(selectedBranchValues[0].value) : null;
    //     GET_ClientGroup<GroupGroupSubgroupViewModel>(null, branchId, {
    //       fetched: { dataFetched, setDataFetched }, data: {
    //         data: groups, setData: setGenders
    //     }
    //     }, ggsConvert.toGroupGroupSubgroupViewModel).then((response) => {
    //       console.log({ response })
    //     })
    //   }
    // }

    const branchId = Array.isArray(selectedBranchValues) && (selectedBranchValues.length > 0)
      ? Number(selectedBranchValues[0].value) : null;
    if (branchId !== selectedBranchId) {
      setSelectedBranchId(branchId);
      GET_ClientGroup<GroupGroupSubgroupViewModel>(null, branchId, {
        fetched: { dataFetched, setDataFetched }, data: {
          data: groups, setData: setGenders
        }
      }, ggsConvert.toGroupGroupSubgroupViewModel).then((response) => {
        // console.log({ response })
      })
    }

  }, [dataFetched, groups, data, selectedBranchValues, selectedBranchId])

  return (
    <div id="select">
      <SelectFormInput
        id={id} name={name} label={label}
        data={data === null ? groups.map(group => {
          return {
            label: String(group.group ?? ""),
            value: String(group.id ?? ""),
          }
        }) : data}
        required={required}
        multiple={multiple}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        validation_identifier="Group(s)" />
    </div>
  );
}