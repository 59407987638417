import { useState } from 'react'
import { BranchSelectFormInput } from '../../components/form/inputs/select/branch/BranchSelectFormInput'
import { GroupSelectFormInput } from '../../components/form/inputs/select/groupings/GroupSelectFormInput'
import { MemberCategorySelectFormInput } from '../../components/form/inputs/select/member_category/MemberCategorySelectFormInput'
import { SubGroupSelectFormInput } from '../../components/form/inputs/select/groupings/SubGroupSelectFormInput'
import { SelectOptionsInterface2 as SelectOptionsInterface } from '../../utils/interfaces/views/form/inputs/select'

export const Grouping = () => {
  const [selectedBranchValues, setSelectedBranchValues] = useState<Array<SelectOptionsInterface>>([])
  const [selectedMemberCategoryValues, setSelectedMemberCategoryValues] = useState<Array<SelectOptionsInterface>>([])
  const [selectedGroupsValues, setSelectedGroupsValues] = useState<Array<SelectOptionsInterface>>([])
  const [selectedSubgroupsValues, setSelectedSubgroupsValues] = useState<Array<SelectOptionsInterface>>([])
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 grid-flow-row gap-4 pb-5'>
      <div className='p-2'>
        <BranchSelectFormInput
          label="Select your branch*"
          id='branchId' name='branchId' required
          selectedValues={selectedBranchValues}
          setSelectedValues={setSelectedBranchValues}
        />
      </div>
      <div className='p-2'>
        <MemberCategorySelectFormInput
          label="Select your member category*"
          id='memberType' name='memberType' required
          selectedValues={selectedMemberCategoryValues}
          setSelectedValues={setSelectedMemberCategoryValues} />
      </div>
      <div className='p-2'>
        <GroupSelectFormInput
          label="Select your group(s)"
          id='groupIds' name='groupIds[]' required={false}
          selectedBranchValues={selectedBranchValues}
          selectedValues={selectedGroupsValues} multiple
          setSelectedValues={setSelectedGroupsValues} />
      </div>
      <div className='p-2'>
        <SubGroupSelectFormInput
          label="Select your sub-group(s)"
          id='subgroupIds' name='subgroupIds[]' required={false}
          selectedBranchValues={selectedBranchValues}
          selectedValues={selectedSubgroupsValues} multiple
          setSelectedValues={setSelectedSubgroupsValues} />
      </div>
    </div>
  )
}
