import { Flowbite, DarkThemeToggle } from 'flowbite-react';

export default function AppTheme() {

  return (
    <div className={`block fixed bottom-16 right-7 z-10 cursor-pointer items-center rounded-lg text-sm dark:bg-[#272d3391] bg-[#e7ebee91]
      focus:outline-none ring-2 ring-yellow-400 focus:ring-gray-200 dark:focus:ring-gray-600
    `}>
      <Flowbite>
        <DarkThemeToggle />
      </Flowbite>
    </div>
  )
}