import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { CONSTANTS } from "../../constants";
import {
  CodeClientViewModel, Convert as ccvmConvert
} from "../../interfaces/client/code";
import { TokenUserClientViewModel, Convert as tucConvert } from "../../interfaces/client/token_user";
import { POST_QuickLogin } from "../../network/client/quick_login";
import { GET_Code } from "../../network/code/post";

export const AccountCodeLogin = async (
  url: string,
  accountCode: string | null,
  processLocalData: (localData: string) => void) => {
  // console.log({accountCode});
  

  const getCode = async (accountCode: string) => {
    await GET_Code<CodeClientViewModel>(accountCode, ccvmConvert.toCodeClientViewModel).then((response) => {
      // console.log({ response });
      window.history.replaceState({}, "", url + "?code=" + accountCode)
    }).catch((err) => {
      const MySwal = withReactContent(Swal)

      MySwal.fire({
        title: <p>{err.detail}</p>,
        footer: "",
        html: "",
        icon: "warning",
        // @ts-ignore
        input: "hidden",
      }).then(() => {
        window.location.href = CONSTANTS.URL_CONSTANTS.PAGE_URLS.HOME;
      });
    });
  }

  const localData = localStorage.getItem(CONSTANTS.LOCAL_STORAGE.CODE);

  if (accountCode === null || accountCode === '') {
    if (localData === null) {
      // console.log({"login-instance": 1})
      window.location.href = CONSTANTS.URL_CONSTANTS.PAGE_URLS.HOME;
    } else {
      // console.log({"login-instance": 2})
      // await getCode("_");
      processLocalData(localData);
      const code = ccvmConvert.toCodeClientViewModel(localData);
      await AccountGetToken(code.clientID!);
    }
  } else {
    if (localData === null) {
      // console.log({"login-instance": 3})
      await getCode(accountCode);

      // const _localData = localStorage.getItem(CONSTANTS.LOCAL_STORAGE.CODE);
      // processLocalData(_localData!);

      // const code = ccvmConvert.toCodeClientViewModel(_localData!);
      // AccountGetToken(code.clientID!);
    } else {
      // console.log({"login-instance": 4})
      processLocalData(localData!);

      const code = ccvmConvert.toCodeClientViewModel(localData);
      AccountGetToken(code.clientID!);
    }
    // await getCode(accountCode);
    // processLocalData(localData!);
    // if (localData !== null) {
    //   const code = ccvmConvert.toCodeClientViewModel(localData);
    //   AccountGetToken(code.clientID!);
    // }
    
  }
}

export const AccountGetToken = async (accountId: number) => {
  try {
    const quickLogin = await POST_QuickLogin<TokenUserClientViewModel>(
      accountId, tucConvert.toTokenUserClientViewModel
    );
    // console.log({quickLogin});
  } catch (error) {
    // console.log({"quickLogin-error": error});
    
    const MySwal = withReactContent(Swal)

    MySwal.fire({
      title: <p>Login Failed</p>,
      icon: "error",
    }).then(() => {
      window.location.href = CONSTANTS.URL_CONSTANTS.PAGE_URLS.HOME;
    });
  }
}

export const AccountGetToken2 = (accountId: number) => {
  POST_QuickLogin<TokenUserClientViewModel>(accountId, tucConvert.toTokenUserClientViewModel).then((response) => {
    // console.log({ response });
  }).catch((err) => {
    const MySwal = withReactContent(Swal)

    MySwal.fire({
      title: <p>Login Failed</p>,
      icon: "error",
    }).then(() => {
      window.location.href = CONSTANTS.URL_CONSTANTS.PAGE_URLS.HOME;
    });
  });
}