type vIdentifier = {
  id?: string;
  identifier?: string;
}
export const ValidationIdentifier = (id: string) => {
  const DVI = "data-validation-identifier";
  let identifier: vIdentifier = {};  
  const validationIdentifier = document.querySelectorAll(`[${DVI}]`);

  validationIdentifier.forEach(vIdentifier => {
    // console.log({"vIdentifier.getAttribute(DVI)": vIdentifier.getAttribute(DVI), DVI});
    
    const _identifier: vIdentifier = JSON.parse(vIdentifier.getAttribute(DVI) ?? `{"id": "???", "identifier": "???"}`);

    if (_identifier.id === id) {
      identifier = _identifier;
    }
  });

  console.log({id, '`[${DVI}="${id}"]`': `[${DVI}="${id}"]`, identifier, validationIdentifier});

  return identifier;
}
