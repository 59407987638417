import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { FooterComponent } from './components/footer/FooterComponent';
import { CONSTANTS } from './utils/constants';
import { HomeView } from './views/home/HomeView';
import { IndividualView } from './views/individual/IndividualView';
import { OrganizationView } from './views/organization/OrganizationView';

function App() {
  return (
    <div className="App bg-gray-100 shadow dark:bg-gray-900">
      <Router>
        <Routes>
          <Route path={CONSTANTS.URL_CONSTANTS.PAGE_URLS.HOME} element={<HomeView />} />
          <Route path={CONSTANTS.URL_CONSTANTS.PAGE_URLS.INDIVIDUAL} element={<IndividualView />} />
          <Route path={CONSTANTS.URL_CONSTANTS.PAGE_URLS.ORGANIZATION} element={<OrganizationView />} />
        </Routes>
        <FooterComponent />
      </Router>
    </div>
  );
}

export default App;
