import { Label, TextInput } from 'flowbite-react'
import { useState } from 'react'
import { ConstituencySelectFormInput } from '../../components/form/inputs/select/location/constituency/ConstituencySelectFormInput'
import { CountrySelectFormInput } from '../../components/form/inputs/select/location/country/CountrySelectFormInput'
import { DistrictSelectFormInput } from '../../components/form/inputs/select/location/district/DistrictSelectFormInput'
import { RegionSelectFormInput } from '../../components/form/inputs/select/location/region/RegionSelectFormInput'
import { SelectOptionsInterface2 as SelectOptionsInterface } from '../../utils/interfaces/views/form/inputs/select'

export const Residence = () => {
  const [selectedCountryValues, setSelectedCountryValues] = useState<Array<SelectOptionsInterface>>([])
  const [selectedRegionValues, setSelectedRegionValues] = useState<Array<SelectOptionsInterface>>([{label:"",value: "1"}])
  const [selectedDistrictValues, setSelectedDistrictValues] = useState<Array<SelectOptionsInterface>>([{label:"",value: "1"}])
  const [selectedConstituencyValues, setSelectedConstituencyValues] = useState<Array<SelectOptionsInterface>>([{label:"",value: "1"}])

  const countryId = Array.isArray(selectedCountryValues) && (selectedCountryValues.length > 0)
      ? Number(selectedCountryValues[0].value) : 0;
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 grid-flow-row gap-4 pb-5'>
      <div className='p-2'>
        <CountrySelectFormInput
          label="Select country*"
          id='nationality' name='nationality' required
          selectedValues={selectedCountryValues}
          setSelectedValues={setSelectedCountryValues}
        />
      </div>
      <div className='p-2 hidden'>
        <CountrySelectFormInput
          label="Select country of residence*"
          id='countryOfResidence' name='countryOfResidence' required
          selectedValues={selectedCountryValues}
          setSelectedValues={setSelectedCountryValues}
        />
      </div>
      <div className={`p-2 ${countryId === 76? "hidden": "block"}`}>
        <div className="mb-2 block">
          <Label
            htmlFor="stateProvince"
            value="Enter State/ Province"
            data-validation-identifier={`{ "id": "stateProvince", "identifier": "State/ Province" }`} />
        </div>
        <TextInput
          id="stateProvince"
          name='stateProvince'
          type="text"
          defaultValue={countryId === 76? "-": ""}
          placeholder="State/ Province"
          required={false} />
      </div>
      <div className={`p-2 ${countryId === 76? "block": "hidden"}`}>
        <RegionSelectFormInput
          label="Select region*"
          id='region' name='region' required
          selectedValues={selectedRegionValues}
          setSelectedValues={setSelectedRegionValues}
        />
      </div>
      <div className={`p-2 ${countryId === 76? "block": "hidden"}`}>
        <DistrictSelectFormInput
          label="Select district*"
          id='district' name='district' required
          selectedValues={selectedDistrictValues}
          selectedRegionValues={selectedRegionValues}
          setSelectedValues={setSelectedDistrictValues}
        />
      </div>
      <div className={`p-2 ${countryId === 76? "block": "hidden"}`}>
        <ConstituencySelectFormInput
          label="Select constituency*"
          id='constituency' name='constituency' required
          selectedValues={selectedConstituencyValues}
          selectedRegionValues={selectedRegionValues}
          selectedDistrictValues={selectedDistrictValues}
          setSelectedValues={setSelectedConstituencyValues}
        />
      </div>
      <div className='p-2'>
        <div className="mb-2 block">
          <Label
            htmlFor="community"
            value="Enter community"
            data-validation-identifier={`{ "id": "community", "identifier": "Community" }`} />
        </div>
        <TextInput
          id="community"
          name='community'
          type="text"
          placeholder="Community"
          required={false} />
      </div>
    </div>
  )
}
