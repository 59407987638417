import { useState } from "react";
import { Label, TextInput } from "flowbite-react";
import { SelectOptionsInterface2 as SelectOptionsInterface } from "../../utils/interfaces/views/form/inputs/select";
import { DisabilitySelectFormInput } from "../../components/form/inputs/select/disability/DisabilitySelectFormInput";
import { MaritalStatusSelectFormInput } from "../../components/form/inputs/select/status/marital_status/MaritalStatusSelectFormInput";
import { EducationStatusSelectFormInput } from "../../components/form/inputs/select/status/education_status/EducationStatusSelectFormInput";
import { ProfessionStatusSelectFormInput } from "../../components/form/inputs/select/status/profession_status/ProfessionStatusSelectFormInput";
import { OccupationStatusSelectFormInput } from "../../components/form/inputs/select/status/occupation_status/OccupationStatusSelectFormInput";

export const Status = () => {
  const [selectedDisabilitySelectFormInput, setSelectedDisabilitySelectFormInput] = useState<Array<SelectOptionsInterface>>([]);

  const [selectedProfessionStatusSelectFormInput, setSelectedProfessionStatusSelectFormInput] = useState<Array<SelectOptionsInterface>>([]),
    otherProfessionStatus = () => {
      const professionStatus = Array.isArray(selectedProfessionStatusSelectFormInput)
        && (selectedProfessionStatusSelectFormInput.length > 0)
        ? selectedProfessionStatusSelectFormInput[0].value : "";
      return professionStatus === "other_value";
    };

  const [selectedOccupationStatusSelectFormInput, setSelectedOccupationStatusSelectFormInput] = useState<Array<SelectOptionsInterface>>([]),
    otherOccupationStatus = () => {
      const occupationalStatus = Array.isArray(selectedOccupationStatusSelectFormInput)
        && (selectedOccupationStatusSelectFormInput.length > 0)
        ? selectedOccupationStatusSelectFormInput[0].value : "";
      return occupationalStatus === "other_value";
    };

  const [selectedEducationStatusSelectFormInput, setSelectedEducationStatusSelectFormInput] = useState<Array<SelectOptionsInterface>>([]),
    otherEducationStatus = () => {
      const educationalStatus = Array.isArray(selectedEducationStatusSelectFormInput)
        && (selectedEducationStatusSelectFormInput.length > 0)
        ? selectedEducationStatusSelectFormInput[0].value : "";
      return educationalStatus === "other_value";
    };

  const [selectedMaritalStatusSelectFormInput, setSelectedMaritalStatusSelectFormInput] = useState<Array<SelectOptionsInterface>>([]),
    otherMaritalStatus = () => {
      const maritalStatus = Array.isArray(selectedMaritalStatusSelectFormInput)
        && (selectedMaritalStatusSelectFormInput.length > 0)
        ? selectedMaritalStatusSelectFormInput[0].value : "";
      return maritalStatus === "other_value";
    };
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 grid-flow-row gap-4 pb-5'>
      <div className='p-2'>
        <DisabilitySelectFormInput
          label="Do you have any Disability?"
          id='disability' name='disability' required
          selectedValues={selectedDisabilitySelectFormInput}
          setSelectedValues={setSelectedDisabilitySelectFormInput} />
      </div>
      <div className='p-2'>
        <MaritalStatusSelectFormInput
          label="Select Marital Status"
          id='maritalStatus' name='maritalStatus' required
          selectedValues={selectedMaritalStatusSelectFormInput}
          setSelectedValues={setSelectedMaritalStatusSelectFormInput} />
      </div>
      <div className={`p-2 ${otherMaritalStatus() ? "block" : "hidden"}`}>
        <div className="mb-2 block">
          <Label
            htmlFor="other_maritalStatus"
            value={`Enter other marital status${otherMaritalStatus() ? "*" : ""}`}
              data-validation-identifier={`{ "id": "other_maritalStatus", "identifier": "Other Marital Status" }`} />
        </div>
        <TextInput
          id="other_maritalStatus"
          name='other_maritalStatus'
          type="text"
          placeholder={`Other marital status${otherMaritalStatus() ? "*" : ""}`}
          disabled={otherMaritalStatus() ? false : true}
          required={otherMaritalStatus()} />
      </div>
      <div className='p-2'>
        <EducationStatusSelectFormInput
          label="Select Education"
          id='educationalStatus' name='educationalStatus' required
          selectedValues={selectedEducationStatusSelectFormInput}
          setSelectedValues={setSelectedEducationStatusSelectFormInput} />
      </div>
      <div className={`p-2 ${otherEducationStatus() ? "block" : "hidden"}`}>
        <div className="mb-2 block">
          <Label
            htmlFor="other_educationalStatus"
            value={`Enter other education status${otherEducationStatus() ? "*" : ""}`}
              data-validation-identifier={`{ "id": "other_educationalStatus", "identifier": "Other Education Status" }`} />
        </div>
        <TextInput
          id="other_educationalStatus"
          name='other_educationalStatus'
          type="text"
          placeholder={`Other education status${otherEducationStatus() ? "*" : ""}`}
          disabled={otherEducationStatus() ? false : true}
          required={otherEducationStatus()} />
      </div>
      <div className='p-2'>
        <OccupationStatusSelectFormInput
          label="Select Occupation"
          id='occupationalStatus' name='occupationalStatus' required
          selectedValues={selectedOccupationStatusSelectFormInput}
          setSelectedValues={setSelectedOccupationStatusSelectFormInput} />
      </div>
      <div className={`p-2 ${otherOccupationStatus() ? "block" : "hidden"}`}>
        <div className="mb-2 block">
          <Label
            htmlFor="other_occupationalStatus"
            value={`Enter other occupation status${otherOccupationStatus() ? "*" : ""}`}
              data-validation-identifier={`{ "id": "other_occupationalStatus", "identifier": "Other Occupation Status" }`} />
        </div>
        <TextInput
          id="other_occupationalStatus"
          name='other_occupationalStatus'
          type="text"
          placeholder={`Other occupation status${otherOccupationStatus() ? "*" : ""}`}
          disabled={otherOccupationStatus() ? false : true}
          required={otherOccupationStatus()} />
      </div>
      <div className='p-2'>
        <ProfessionStatusSelectFormInput
          label="Select Profession"
          id='professionStatus' name='professionStatus' required
          selectedValues={selectedProfessionStatusSelectFormInput}
          setSelectedValues={setSelectedProfessionStatusSelectFormInput} />
      </div>
      <div className={`p-2 ${otherProfessionStatus() ? "block" : "hidden"}`}>
        <div className="mb-2 block">
          <Label
            htmlFor="other_professionStatus"
            value={`Enter other profession status${otherProfessionStatus() ? "*" : ""}`}
              data-validation-identifier={`{ "id": "other_professionStatus", "identifier": "Other Profession Status" }`} />
        </div>
        <TextInput
          id="other_professionStatus"
          name='other_professionStatus'
          type="text"
          placeholder={`Other profession status${otherProfessionStatus() ? "*" : ""}`}
          disabled={otherProfessionStatus() ? false : true}
          required={otherProfessionStatus()} />
      </div>
    </div>
  )
}

// "maritalStatus": "1",
// "other_maritalStatus": "-",
// "occupationalStatus": "1",
// "other_occupationalStatus": "-",
// "educationalStatus": "1",
// "other_educationalStatus": "-",