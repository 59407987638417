import { Button, Navbar, Avatar } from 'flowbite-react'
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { CONSTANTS } from '../../utils/constants';
import { CodeClientViewModel, Convert as ccvmConvert } from '../../utils/interfaces/client/code';

export const NavbarComponent = (properties: { organization: boolean, individual: boolean }) => {
  const _organizationValue = properties.organization,
    _individualValue = properties.individual;
  const [searchParams] = useSearchParams(),
    [clientCode, setClientCode] = useState<CodeClientViewModel | null>(null),
    navigate = useNavigate(),
    processLocalData = () => {
      const localData = localStorage.getItem(CONSTANTS.LOCAL_STORAGE.CODE);
      // console.log({ localData })
      if (localData !== null) {
        const cC = ccvmConvert.toCodeClientViewModel(localData);
        // console.log({ cC })
        setClientCode(cC);
      }
    },
    accountCode = searchParams.get("code"),
    codeParams = accountCode === null ? "" : `?code=${accountCode}`;
  // console.log({ 'searchParams.get("code")': accountCode })

  useEffect(() => {
    processLocalData()
  }, [])


  const goToHome = () => {
    navigate(CONSTANTS.URL_CONSTANTS.PAGE_URLS.HOME);
  }

  const goToIndividualForm = () => {
    navigate(CONSTANTS.URL_CONSTANTS.PAGE_URLS.INDIVIDUAL + codeParams);
  }

  const goToOrganizationForm = () => {
    navigate(CONSTANTS.URL_CONSTANTS.PAGE_URLS.ORGANIZATION + codeParams);
  }

  return (
    <>
      <div className='bg-white dark:bg-gray-800 shadow'>
        <Navbar
          fluid={true}
          rounded={true} >
          {/* <Navbar.Brand>
          <img
            src={clientCode?.clientInfo?.logo}
            className="mr-3 h-14"
            alt={clientCode?.clientInfo?.name + ` : Logo`}
          />
          <span className="self-center whitespace-nowrap text-lg font-semibold dark:text-white">
            {clientCode?.clientInfo?.name}
          </span>
        </Navbar.Brand> */}
          <div></div>
          <div className="flex md:order-2">
            <span className='px-2'>
              <Button onClick={goToHome}>
                Home
              </Button>
            </span>
            <Navbar.Toggle />
          </div>
          <Navbar.Collapse>
            <Navbar.Link onClick={goToIndividualForm}
              active={_individualValue} >
              Register As Member
            </Navbar.Link>
            <Navbar.Link onClick={goToOrganizationForm}
              active={_organizationValue} >
              Register As Organization
            </Navbar.Link>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <div className='flex flex-col justify-center self-center items-center mt-2'>
        <Avatar
          size="xl"
          bordered={true}
          rounded={true}
          status="online"
          img={clientCode?.clientInfo?.logo} />
        <h4 className="self-center whitespace-nowrap text-xl uppercase font-semibold dark:text-white underline underline-offset-8 mt-2">
          {clientCode?.clientInfo?.name}
        </h4>
      </div>
    </>
  )
}
