import { useState } from 'react';
import { SelectFormInput } from '../SelectFormInput';
import { SelectOptionsInterface2 as SelectOptionsInterface } from '../../../../../utils/interfaces/views/form/inputs/select';

export const DisabilitySelectFormInput = (properties: {
  label: string; name?: string; id?: string; required?: boolean;
  data?: Array<SelectOptionsInterface>, multiple?: boolean;
  selectedValues?: Array<SelectOptionsInterface>,
  setSelectedValues?: React.Dispatch<React.SetStateAction<Array<SelectOptionsInterface>>>
}) => {
  const [_selectedValues, _setSelectedValues] = useState<Array<SelectOptionsInterface>>([{ label: "", value: "0" }])
  const label = properties.label,
    id = properties.id ?? "",
    name = properties.name ?? "",
    multiple = properties.multiple ?? false,
    required = properties.required ?? false,
    selectedValues = properties.selectedValues ?? _selectedValues,
    setSelectedValues = properties.setSelectedValues ?? _setSelectedValues,
    data = properties.data ?? null;
  const options: Array<{ name: string, id: string }> = [
    { name: "Select ", id: "true" }, { name: "Yes", id: "true" }, { name: "No", id: "false" },
  ];
  
  return (
    <div id="select">
      <SelectFormInput
        id={id} name={name} label={label}
        data={data === null ? options.map(option => {
          return {
            label: String(option.name ?? ""),
            value: String(option.id ?? ""),
            // id: String(option.id),
            // value: option.name ?? "", selected: false
          }
        }) : data}
        required={required}
        multiple={multiple}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        validation_identifier="Disability" />
    </div>
  );
}