import {
  Label, TextInput, Button
} from 'flowbite-react';
import {
  CodeClientViewModel, Convert as ccvmConvert
} from '../../utils/interfaces/client/code';
import { useEffect, useState } from 'react';
import { CONSTANTS } from '../../utils/constants';
// import Logo from '../../utils/assets/logo/icon.png';
import { GET_Code } from '../../utils/network/code/post';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useNavigate } from 'react-router-dom';

export const HomeView = () => {
  const [code, setCode] = useState(''),
    navigate = useNavigate();
  useEffect(() => {
    localStorage.removeItem(CONSTANTS.LOCAL_STORAGE.CODE);
  }, [])

  const handleSubmit = (e: any) => {
    e.preventDefault();

    GET_Code<CodeClientViewModel>(code, ccvmConvert.toCodeClientViewModel).then((response) => {
      // console.log({ response });
      const MySwal = withReactContent(Swal)

      MySwal.fire({
        icon: "success",
      }).then(() => {
        setTimeout(() => {
          navigate(CONSTANTS.URL_CONSTANTS.PAGE_URLS.INDIVIDUAL + "?code=" + code);
        }, 200);
      });
    }).catch((err) => {
      // console.log({ err });
      const MySwal = withReactContent(Swal)

      MySwal.fire({
        title: <p>{err.detail}</p>,
        icon: "warning",
      });
    });
  }

  return (
    <div className='py-[25vh] md:py-[35vh] text-black dark:text-white p-3'>
      <div className='flex justify-center'>
        {/* <img src={Logo} className="h-32" alt="Logo" /> */}
        <div className="h-32"></div>
      </div>
      <div className='text-sm w-full'>
        <form className="flex justify-center flex-col mx-auto" onSubmit={handleSubmit}>
          <div className='w-full md:w-[35rem] mx-auto'>
            <div>
              <div className="mb-2 flex justify-center">
                <Label
                  htmlFor="code"
                  value="Enter Registration Code"
                />
              </div>
              <TextInput
                id="code"
                type="text"
                placeholder="Enter Registration Code"
                value={code}
                onChange={(e) => { setCode(e.target.value) }}
                required={true}
                shadow={true}
              />
            </div>
            <div className='flex justify-center mt-2'>
              <Button type="submit" color="dark">
                Enter
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
