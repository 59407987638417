import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const CheckFormErrorBeforeSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, formSelector: string) => {
  const formContainer: HTMLFormElement | null = document.querySelector(formSelector),
    formData = new FormData(formContainer!);

  let requiredCount = 0;
  formData.forEach((e, i, a) => {
    // document.querySelectorAll('[id=' + i.replaceAll("[]", "") + ']').forEach(input => {
    document.querySelectorAll('[name=' + i.split('[]').join('') + ']').forEach(input => {
      //@ts-ignore
      const requiredField = input.required;
      // console.log({ "input.required": requiredField })
      if (requiredField && e === "") {
        requiredCount += 1;
      }
    })
    // console.log({ e, i, });
  })
  // console.log({ requiredCount, });
  if (requiredCount > 0) {
    e.preventDefault();
    const MySwal = withReactContent(Swal)

    MySwal.fire({
      title: "<p>Some required fields are still empty...</p>",
      icon: "warning",
    });
  } else {
    // alert("submiting form")
  }
}