import { Button, Label, TextInput, Tooltip } from 'flowbite-react'
import { useEffect, } from 'react'
import { HiInformationCircle } from 'react-icons/hi'
import { CheckFormErrorBeforeSubmit } from '../../utils/functions/check_form_error'
import { togglePswdVisibility } from '../../utils/functions/toggle_pswd_visibility'

export const Password = () => {

  useEffect(() => {
    togglePswdVisibility();
  }, [])

  return (
    <>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 grid-flow-row gap-4 pb-5'>
        <div className='p-2'>
          <div className="mb-2 block">
            <div className='block relative'>
              <span className='z-50 relative w-64'>
                <Tooltip content="Password must contain at least 8 characters, with a mix of upper case & lower case letters, numbers & symbols.">
                  <HiInformationCircle size={25} />
                </Tooltip>
              </span>
            </div>
            <Label
              htmlFor="password"
              value="Enter password*"
              data-validation-identifier={`{ "id": "password", "identifier": "Password" }`} />
          </div>
          <TextInput
            id="password"
            name='password'
            type="password"
            placeholder="Password*"
            required={true} />
        </div>
        <div className='p-2'>
          <div className="mb-2 block">
            <div className='block relative'>
              <span className='z-50 relative w-64'>
                <Tooltip content="Password must contain at least 8 characters, with a mix of upper case & lower case letters, numbers & symbols.">
                  <HiInformationCircle size={25} />
                </Tooltip>
              </span>
            </div>
            <Label
              htmlFor="confirm_password"
              value="Enter password confirmation*"
              data-validation-identifier={`{ "id": "confirm_password", "identifier": "Password Confirmation" }`} />
          </div>
          <TextInput
            id="confirm_password"
            name='confirm_password'
            type="password"
            placeholder="Password Confirmation*"
            required={true} />
        </div>
      </div>
      <div className='flex justify-center mt-2'>
        <Button type="submit" color={"warning"}
          onClick={(e) =>
            CheckFormErrorBeforeSubmit(e, '[make-general-posts="organization-user-registration"]')
          }>
          <div className='w-[70vw] max-w-full'>Submit Form</div>
        </Button>
      </div>
    </>
  )
}
