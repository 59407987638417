import { Label } from 'flowbite-react';
import { useState } from 'react';
import { SelectOptionsInterface2 as SelectOptionsInterface } from '../../../../utils/interfaces/views/form/inputs/select';
import Select from 'react-select'
import './SelectFormInput.scss'

export const SelectFormInput = (properties: {
  label: string; name?: string; id?: string;
  multiple?: boolean; required?: boolean; validation_identifier?: string; 
  data?: Array<SelectOptionsInterface>, selectedValues?: Array<SelectOptionsInterface>,
  setSelectedValues?: React.Dispatch<React.SetStateAction<Array<SelectOptionsInterface>>>
}) => {
  const [_selectedValues, _setSelectedValues] = useState<Array<SelectOptionsInterface>>([])
  const label = properties.label,
    id = properties.id ?? "",
    name = properties.name ?? "",
    multiple = properties.multiple ?? false,
    // required = properties.required ?? false,
    selectedValues = properties.selectedValues ?? _selectedValues,
    validation_identifier = properties.validation_identifier ?? "",
    setSelectedValues = properties.setSelectedValues ?? _setSelectedValues,
    data = properties.data ?? [];

  return (
    <>
      <div className="mb-2 block">
        <Label
          htmlFor={id}
          value={label}
          data-validation-identifier={`{ "id": "${id}", "identifier": "${validation_identifier}" }`} />
      </div>
      <Select
        options={data}
        value={selectedValues}
        isMulti={multiple}
        closeMenuOnSelect={multiple?false: true}
        onChange={(e) => {
          // console.log({ e });
          if (multiple) {
            // @ts-ignore
            const values:Array<SelectOptionsInterface> = e;
            // console.log({ values });
            setSelectedValues(values)
          } else {
            // @ts-ignore
            const value:SelectOptionsInterface = e;
            // console.log({ value });
            setSelectedValues([value])
          }
        }}
        id={id}
        name={name}
        isSearchable
        classNamePrefix="select"
      />
    </>
  )
}