import { FileInput, Label, TextInput } from 'flowbite-react'
import { useState } from 'react'
import { BusinessRegisteredSelectFormInput } from '../../components/form/inputs/select/business_registered/BusinessRegisteredSelectFormInput'
import { SelectGenderFormInput } from '../../components/form/inputs/select/gender/GenderSelectFormInput'
import { OrganizationMemberTypeSelectFormInput } from '../../components/form/inputs/select/organization_member_type/OrganizationMemberTypeSelectFormInput'
import { SelectOptionsInterface2 as SelectOptionsInterface } from '../../utils/interfaces/views/form/inputs/select'

// other_organizationType:New Group

export const BioData = () => {
  const [selectedGenderValues, setSelectedGenderValues] = useState<Array<SelectOptionsInterface>>([])
  const [selectedBusinessRegisteredValues, setSelectedBusinessRegisteredValues] = useState<Array<SelectOptionsInterface>>([])
  const [selectedOrganizationMembershipTypeValues, setSelectedOrganizationMembershipTypeValues] = useState<Array<SelectOptionsInterface>>([])
  
  const businessRegistered = () => {
    const businessRegistered = Array.isArray(selectedBusinessRegisteredValues)
      && (selectedBusinessRegisteredValues.length > 0)
      ? selectedBusinessRegisteredValues[0].value : "false";
    return businessRegistered === "true";
  }
  
  const otherOrganizationType = () => {
    const organizationType = Array.isArray(selectedOrganizationMembershipTypeValues)
      && (selectedOrganizationMembershipTypeValues.length > 0)
      ? selectedOrganizationMembershipTypeValues[0].value : "";
    return organizationType === "other_value";
  }

  return (
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 grid-flow-row gap-4 pb-5'>
      <div className='p-2'>
        <div className="mb-2 block">
          <Label
            htmlFor="organizationName"
            value="Enter organization name*"
            data-validation-identifier={`{ "id": "organizationName", "identifier": "Organization Name" }`} />
        </div>
        <TextInput
          id="organizationName"
          name='organizationName'
          type="text"
          placeholder="Organization name*"
          required={true} />
      </div>
      <div className='p-2'>
        <div className="mb-2 block">
          <Label
            htmlFor="contactPersonName"
            value="Enter contact person name*"
            data-validation-identifier={`{ "id": "contactPersonName", "identifier": "Contact Person Name" }`} />
        </div>
        <TextInput
          id="contactPersonName"
          name='contactPersonName'
          type="text"
          placeholder="Contact person name*"
          required={true} />
      </div>
      <div className='p-2'>
        <div className="mb-2 block">
          <Label
            htmlFor="logo"
            value="Upload logo"
            data-validation-identifier={`{ "id": "logo", "identifier": "Upload Logo" }`} />
        </div>
        <FileInput
          id="logo"
          name='logo'
          helperText="Please upload logo"
          required={false}
        />
      </div>
      <div className='p-2'>
        <div className="mb-2 block">
          <Label
            htmlFor="contactPersonPhoto"
            value="Upload contact person photo"
            data-validation-identifier={`{ "id": "contactPersonPhoto", "identifier": "Upload Contact Person Photo" }`} />
        </div>
        <FileInput
          id="contactPersonPhoto"
          name='contactPersonPhoto'
          helperText="Please upload contact Person photo"
          required={false}
        />
      </div>
      <div className='p-2'>
        <SelectGenderFormInput
          label="Select your contact person gender*"
          id='contactPersonGender' name='contactPersonGender' required
          selectedValues={selectedGenderValues}
          setSelectedValues={setSelectedGenderValues} />
      </div>
      <div className='p-2'>
        <BusinessRegisteredSelectFormInput
          label="Is your Organization legally registered?*"
          id='businessRegistered' name='businessRegistered' required
          selectedValues={selectedBusinessRegisteredValues}
          setSelectedValues={setSelectedBusinessRegisteredValues} />
      </div>
      <div className={`p-2 ${businessRegistered()? 'block': 'hidden'}`}>
        <div className="mb-2 block">
          <Label
            htmlFor="dateOfIncorporation"
            value={`Select date of registration${businessRegistered()? "*": ""}`}
            data-validation-identifier={`{ "id": "dateOfIncorporation", "identifier": "Date Of Incorporation" }`} />
        </div>
        <TextInput
          id="dateOfIncorporation"
          name='dateOfIncorporation'
          type="date"
          placeholder={`Date of registration${businessRegistered()? "*": ""}`}
          required={businessRegistered()} />
      </div>
      <div className={`p-2 ${businessRegistered()? 'block': 'hidden'}`}>
        <div className="mb-2 block">
          <Label
            htmlFor="certificates"
            value={`Select registration certificates${businessRegistered()? "*": ""}`}
            data-validation-identifier={`{ "id": "certificates", "identifier": "Business Registration Certificate(s)" }`} />
        </div>
        <FileInput
          id="certificates"
          name='certificates'
          multiple
          helperText="Please upload certificate(s)"
          placeholder={`Please upload certificate(s)*`}
          required={businessRegistered()} />
      </div>
      <div className='p-2'>
        <OrganizationMemberTypeSelectFormInput
          label="Organization Type/Description*"
          id='organizationType' name='organizationType' required
          selectedValues={selectedOrganizationMembershipTypeValues}
          setSelectedValues={setSelectedOrganizationMembershipTypeValues} />
      </div>
      <div className={`p-2 ${otherOrganizationType()? "block": "hidden"}`}>
        <div className="mb-2 block">
          <Label
            htmlFor="other_organizationType"
            value={`Enter other organization type${otherOrganizationType()? "*": ""}`}
            data-validation-identifier={`{ "id": "other_organizationType", "identifier": "Other Organization Type" }`} />
        </div>
        <TextInput
          id="other_organizationType"
          name='other_organizationType'
          type="text"
          placeholder={`Other organization type${otherOrganizationType() ? "*" : ""}`}
          disabled={otherOrganizationType()? false: true}
          required={otherOrganizationType()} />
      </div>
      <div className='p-2'>
        <div className="mb-2 block">
          <Label
            htmlFor="organizationEmail"
            value="Enter organization email address*"
            data-validation-identifier={`{ "id": "organizationEmail", "identifier": "Organization E-mail Address" }`} />
        </div>
        <TextInput
          id="organizationEmail"
          name='organizationEmail'
          type="email"
          placeholder="Organization email address*"
          required={true} />
      </div>
      <div className='p-2'>
        <div className="mb-2 block">
          <Label
            htmlFor="organizationPhone"
            value="Enter organization phone number*"
            data-validation-identifier={`{ "id": "organizationPhone", "identifier": "Organization Phone Number" }`} />
        </div>
        <TextInput
          id="organizationPhone"
          name='organizationPhone'
          type="tel" maxLength={12} minLength={9}
          placeholder="Organization phone number*"
          required={true} />
      </div>
      <div className='p-2'>
        <div className="mb-2 block">
          <Label
            htmlFor="contactPersonEmail"
            value="Enter contact person email address*"
            data-validation-identifier={`{ "id": "contactPersonEmail", "identifier": "Contact Person E-mail Address" }`} />
        </div>
        <TextInput
          id="contactPersonEmail"
          name='contactPersonEmail'
          type="email"
          placeholder="Contact person email address*"
          required={true} />
      </div>
      <div className='p-2'>
        <div className="mb-2 block">
          <Label
            htmlFor="contactPersonPhone"
            value="Enter contact person phone number*" 
            data-validation-identifier={`{ "id": "contactPersonPhone", "identifier": "Contact Person Phone Number" }`} />
        </div>
        <TextInput
          id="contactPersonPhone"
          name='contactPersonPhone'
          type="tel" maxLength={12} minLength={9}
          placeholder="Contact person phone number*"
          required={true} />
      </div>
      <div className='p-2'>
        <div className="mb-2 block">
          <Label
            htmlFor="contactPersonWhatsapp"
            value="Enter contact person whatsapp number"
            data-validation-identifier={`{ "id": "contactPersonWhatsapp", "identifier": "Contact Person Whatsapp Number" }`} />
        </div>
        <TextInput
          id="contactPersonWhatsapp"
          name='contactPersonWhatsapp'
          type="tel" maxLength={12} minLength={9}
          placeholder="Contact person whatsapp number"
          required={false} />
      </div>
      <input type="hidden" data-validation-identifier={`{ "id": "non_field_errors", "identifier": "Message" }`} />
    </div>
  )
}
