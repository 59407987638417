import axios from 'axios';
import { CONSTANTS } from "../../../constants";
import { AccountGetToken } from '../../../functions/code_login';

export async function GET_Code<Type>(
  code: string,
  formater: (json: string) => Type,) {
  const axiosConfig = {
    method: 'get',
    url: CONSTANTS.URL_CONSTANTS.API_BASE_URL + 'clients/code/by-code/' + code,
    headers: {
      'content-Type': 'application/json',
      "Cache-Control": "no-cache",
      "Pragma": "no-cache",
      "Expired": "0",
      "Cookie": document.cookie
    }
  };
  return await axios(axiosConfig).then(async (response) => {
    var data: Type | null = null;
    const DATA = response.data;
    if (DATA.success === true) {
      const stringedData = JSON.stringify(DATA.data);
      data = formater(stringedData);
      // console.log({ "POST_Code-data.data": data });
      localStorage.setItem(CONSTANTS.LOCAL_STORAGE.CODE, stringedData);
      // @ts-ignore
      await AccountGetToken(data.clientID);
    } else {
      // console.log({ "POST_Code-response-false": response });
      throw response;
    }
    // console.log({ "POST_Code-DATA.data": DATA.data });
    return data;
  }).catch((err) => {
    // console.log({ "POST_Code-err": err });
    throw err.response.data;

  });
}