import { FileInput, Label, TextInput } from 'flowbite-react'
import { useState } from 'react'
import { SelectGenderFormInput } from '../../components/form/inputs/select/gender/GenderSelectFormInput'
import { SelectOptionsInterface2 as SelectOptionsInterface } from '../../utils/interfaces/views/form/inputs/select'

export const BioData = () => {
  const [selectedGenderValues, setSelectedGenderValues] = useState<Array<SelectOptionsInterface>>([])
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 grid-flow-row gap-4 pb-5'>
      <div className='p-2'>
        <div className="mb-2 block">
          <Label
            htmlFor="firstname"
            value="Enter first name*"
            data-validation-identifier={`{ "id": "firstname", "identifier": "First Name" }`} />
        </div>
        <TextInput
          id="firstname"
          name='firstname'
          type="text"
          placeholder="First name*"
          required={true} />
      </div>
      <div className='p-2'>
        <div className="mb-2 block">
          <Label
            htmlFor="middlename"
            value="Enter middle name"
            data-validation-identifier={`{ "id": "middlename", "identifier": "Middle Name" }`} />
        </div>
        <TextInput
          id="middlename"
          name='middlename'
          type="text"
          placeholder="Middle name"
          required={false} />
      </div>
      <div className='p-2'>
        <div className="mb-2 block">
          <Label
            htmlFor="surname"
            value="Enter surname*"
            data-validation-identifier={`{ "id": "surname", "identifier": "Surname" }`} />
        </div>
        <TextInput
          id="surname"
          name='surname'
          type="text"
          placeholder="Surname*"
          required={true} />
      </div>
      <div className='p-2'>
        <div className="mb-2 block">
          <Label
            htmlFor="dateOfBirth"
            value="Select date of birth*" 
            data-validation-identifier={`{ "id": "dateOfBirth", "identifier": "Date of Birth" }`} />
        </div>
        <TextInput
          id="dateOfBirth"
          name='dateOfBirth'
          type="date"
          placeholder="Date of birth*"
          required={true} />
      </div>
      <div className='p-2'>
        {/* {selectedGenderValues} */}
        <SelectGenderFormInput
          label="Select your gender*"
          id='gender' name='gender' required
          selectedValues={selectedGenderValues}
          setSelectedValues={setSelectedGenderValues} />
      </div>
      <div className='p-2'>
        <div className="mb-2 block">
          <Label
            htmlFor="profilePicture"
            value="Upload profile picture"
            data-validation-identifier={`{ "id": "profilePicture", "identifier": "Profile Picture Upload" }`} />
        </div>
        <FileInput
          id="profilePicture"
          name='profilePicture'
          helperText="Please upload a profile image"
          required={false}
        />
      </div>
      <div className='p-2'>
        <div className="mb-2 block">
          <Label
            htmlFor="email"
            value="Enter email address*"
            data-validation-identifier={`{ "id": "email", "identifier": "E-mail Address" }`} />
        </div>
        <TextInput
          id="email"
          name='email'
          type="email"
          placeholder="Email Address*"
          required={true} />
      </div>
      <div className='p-2'>
        <div className="mb-2 block">
          <Label
            htmlFor="phone"
            value="Enter phone number*"
            data-validation-identifier={`{ "id": "phone", "identifier": "Phone Number" }`} />
        </div>
        <TextInput
          id="phone"
          name='phone'
          type="tel" maxLength={12} minLength={9}
          placeholder="Phone number*"
          required={true} />
      </div>
      <div className='p-2'>
        <div className="mb-2 block">
          <Label
            htmlFor="whatsapp"
            value="Enter whatsapp number"
            data-validation-identifier={`{ "id": "whatsapp", "identifier": "WhatsApp Number" }`} />
        </div>
        <TextInput
          id="whatsapp"
          name='whatsapp'
          type="tel" maxLength={12} minLength={9}
          placeholder="WhatsApp number"
          required={false} />
      </div>
      <div className='p-2'>
        <div className="mb-2 block">
          <Label
            htmlFor="referenceId"
            value="Enter Reference-ID [Optional]"
            data-validation-identifier={`{ "id": "referenceId", "identifier": "Reference-ID" }`} />
        </div>
        <TextInput
          id="referenceId"
          name='referenceId'
          type="text"
          placeholder="Reference-ID [Optional]"
          required={false} />
      </div>
      <div className='p-2'>
        <div className="mb-2 block">
          <Label
            htmlFor="profileResume"
            value="Upload CV [Optional]"
            data-validation-identifier={`{ "id": "profileResume", "identifier": "CV. Upload" }`} />
        </div>
        <FileInput
          id="profileResume"
          name='profileResume'
          helperText="Please upload a CV [Optional]"
          required={false}
        />
      </div>
      <div className='p-2'>
        <div className="mb-2 block">
          <Label
            htmlFor="profileIdentification"
            value="Upload your ID [Optional]"
            data-validation-identifier={`{ "id": "profileIdentification", "identifier": "ID. Upload" }`} />
        </div>
        <FileInput
          id="profileIdentification"
          name='profileIdentification'
          helperText="Please upload a ID (Passport, Voter Id, Driver License, National ID) [Optional]"
          required={false}  
        />
      </div>
      <input type="hidden" data-validation-identifier={`{ "id": "non_field_errors", "identifier": "Message" }`} />
    </div>
  )
}
