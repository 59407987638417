import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CONSTANTS } from "../../../constants";
import { Convert as tucConvert } from '../../../interfaces/client/token_user'

export async function GET_ClientBranch<Type>(
  type: number | null = null, state: {
    fetched: {
      dataFetched: boolean,
      setDataFetched: React.Dispatch<React.SetStateAction<boolean>>
    },
    data: {
      data: Array<Type>,
      setData: React.Dispatch<React.SetStateAction<Array<Type>>>
    },
  },
  formater: (json: string) => Type,) {
  const USER_TOKEN = localStorage.getItem(CONSTANTS.LOCAL_STORAGE.TOKEN);
  
  if (USER_TOKEN !== null) {
    const TOKEN = tucConvert.toTokenUserClientViewModel(USER_TOKEN);
  
  const axiosConfig = {
    method: 'get',
    url: CONSTANTS.URL_CONSTANTS.API_BASE_URL + 'clients/branch'
      + (type === null ? "" : "/" + type),
    headers: {
      'Authorization': 'Token ' + TOKEN.token!, 
      'content-Type': 'application/json',
      "Cache-Control": "no-cache",
      "Pragma": "no-cache",
      "Expired": "0",
      "Cookie": document.cookie
    }
  };
  axios(axiosConfig)
    .then((response) => {
      var data: Array<Type> = [];
      const DATA = response.data;
      // console.log({ "DATA.DATA": DATA });
      if (DATA.success === true) {
        const actualData: Array<any> = DATA.data;
        data = actualData.map(_data => formater(JSON.stringify(_data)));
        // console.log({ "GET_ClientBranch-data.data": data });
      }
      // console.log({ "GET_ClientBranch-DATA.data": DATA.data });
      if (data !== null) {
        // @ts-ignore
        state.data.setData(data);
      }
      state.fetched.setDataFetched(true)
      // console.log({ "this.state.data": this.state.data });
    })
    .catch(function (error) {
      console.log(error);
    });
  } else {
    const MySwal = withReactContent(Swal)

    MySwal.fire({
      title: `<p><b className='text-orange-400'>Branch: </b> Token not found</p>`,
      icon: "error",
    }).then(() => {
      window.location.href = CONSTANTS.URL_CONSTANTS.PAGE_URLS.HOME;
    });
  }
}