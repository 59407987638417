import { useEffect, useState } from 'react';
import { SelectFormInput } from '../../SelectFormInput';
import { SelectOptionsInterface2 as SelectOptionsInterface } from '../../../../../../utils/interfaces/views/form/inputs/select';
import { DistrictLocationViewModel, Convert as dlConvert } from '../../../../../../utils/interfaces/location/district';
import { GET_LocationDistrict } from '../../../../../../utils/network/location/district/get';

export const DistrictSelectFormInput = (properties: {
  label: string; name?: string; id?: string; required?: boolean;
  data?: Array<SelectOptionsInterface>, multiple?: boolean;
  selectedValues?: Array<SelectOptionsInterface>,
  setSelectedValues?: React.Dispatch<React.SetStateAction<Array<SelectOptionsInterface>>>,
  selectedRegionValues?: Array<SelectOptionsInterface>,
}) => {
  const [_selectedValues, _setSelectedValues] = useState<Array<SelectOptionsInterface>>([{label: "", value: "0"}])
  const label = properties.label,
    id = properties.id ?? "",
    name = properties.name ?? "",
    multiple = properties.multiple ?? false,
    required = properties.required ?? false,
    selectedValues = properties.selectedValues ?? _selectedValues,
    setSelectedValues = properties.setSelectedValues ?? _setSelectedValues,
    selectedRegionValues = properties.selectedRegionValues ?? null,
    data = properties.data ?? null;
  const [regions, setGenders] = useState<Array<DistrictLocationViewModel>>([]);
  const [dataFetched, setDataFetched] = useState<boolean>(false);
  const [selectedRegionId, setSelectedRegionId] = useState<number>(0);

  useEffect(() => {
    // console.log({ dataFetched, regions, selectedRegionValues });
    // if (data === null) {
    //   if (!dataFetched) {
    //     GET_LocationDistrict<DistrictLocationViewModel>(null, {
    //       fetched: { dataFetched, setDataFetched }, data: {
    //         data: regions, setData: setGenders
    //       }
    //     }, dlConvert.toDistrictLocationViewModel).then((response) => {
    //       console.log({ response })
    //     })
    //   }
    // }

    const regionId = Array.isArray(selectedRegionValues) && (selectedRegionValues.length > 0)
      ? Number(selectedRegionValues[0].value) : 0;
      if (regionId !== selectedRegionId) {
        setSelectedRegionId(regionId);
        GET_LocationDistrict<DistrictLocationViewModel>(regionId, {
          fetched: { dataFetched, setDataFetched }, data: {
            data: regions, setData: setGenders
          }
        }, dlConvert.toDistrictLocationViewModel).then((response) => {
          // console.log({ response })
        })
      }

  }, [dataFetched, regions, data, selectedRegionId, selectedRegionValues])

  const selectOptions = () => {
    let selectOptions: Array<DistrictLocationViewModel> = [{ location: "Select District", id: 0 }];
    return [...selectOptions, ...regions]
  }
  return (
    <div id="select">
      <SelectFormInput
        id={id} name={name} label={label}
        data={data === null ? selectOptions().map(region => {
          return {
            label: String(region.location ?? ""),
            value: String(region.id ?? ""),
          }
        }) : data}
        required={required}
        multiple={multiple}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        validation_identifier="District" />
    </div>
  );
}