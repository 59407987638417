import { useEffect, useState } from 'react';
import { SelectFormInput } from '../../SelectFormInput';
import { GET_ClientOccupationStatus } from '../../../../../../utils/network/client/status/occupation_status/get';
import { SelectOptionsInterface2 as SelectOptionsInterface } from '../../../../../../utils/interfaces/views/form/inputs/select';
import { OccupationStatusClientViewModel, Convert as mscConvert } from '../../../../../../utils/interfaces/client/status/occupation_status';

export const OccupationStatusSelectFormInput = (properties: {
  label: string; name?: string; id?: string; required?: boolean;
  data?: Array<SelectOptionsInterface>, multiple?: boolean;
  selectedValues?: Array<SelectOptionsInterface>,
  setSelectedValues?: React.Dispatch<React.SetStateAction<Array<SelectOptionsInterface>>>
}) => {
  const [_selectedValues, _setSelectedValues] = useState<Array<SelectOptionsInterface>>([{ label: "", value: "0" }])
  const label = properties.label,
    id = properties.id ?? "",
    name = properties.name ?? "",
    multiple = properties.multiple ?? false,
    required = properties.required ?? false,
    selectedValues = properties.selectedValues ?? _selectedValues,
    setSelectedValues = properties.setSelectedValues ?? _setSelectedValues,
    data = properties.data ?? null;
  const [occupations, setOccupations] = useState<Array<OccupationStatusClientViewModel>>([]);
  const [dataFetched, setDataFetched] = useState<boolean>(false);

  useEffect(() => {
    // console.log({ dataFetched, occupations });
    if (data === null) {
      if (!dataFetched) {
        GET_ClientOccupationStatus<OccupationStatusClientViewModel>(null, {
          fetched: { dataFetched, setDataFetched }, data: {
            data: occupations, setData: setOccupations
          }
        }, mscConvert.toOccupationStatusClientViewModel).then((response) => {
          // console.log({ response })
        })
      }
    }

  }, [dataFetched, occupations, data])

  const selectOptions = () => {
    let selectOptions: Array<OccupationStatusClientViewModel> = [{ name: "Select Occupation Status", id: 0 }];
    return [...selectOptions, ...occupations, ...[{ name: "Other", id: "other_value" }]]
  }
  return (
    <div id="select">
      <SelectFormInput
        id={id} name={name} label={label}
        data={data === null ? selectOptions().map(occupation => {
          return {
            label: String(occupation.name ?? ""),
            value: String(occupation.id ?? ""),
          }
        }) : data}
        required={required}
        multiple={multiple}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        validation_identifier="Occupation Status" />
    </div>
  );
}