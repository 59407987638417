import axios from 'axios';
import { CONSTANTS } from "../../../constants";

export async function GET_GenericGender<Type>(
  type: number | null = null, state: {
    fetched: {
      dataFetched: boolean,
      setDataFetched: React.Dispatch<React.SetStateAction<boolean>>
    },
    data: {
      data: Array<Type>,
      setData: React.Dispatch<React.SetStateAction<Array<Type>>>
    },
  },
  formater: (json: string) => Type,) {
  const axiosConfig = {
    method: 'get',
    url: CONSTANTS.URL_CONSTANTS.API_BASE_URL + 'generic/gender'
      + (type === null ? "" : "/" + type),
    headers: {
      'content-Type': 'application/json',
      "Cache-Control": "no-cache",
      "Pragma": "no-cache",
      "Expired": "0",
      "Cookie": document.cookie
    }
  };
  axios(axiosConfig)
    .then((response) => {
      var data: Array<Type> = [];
      const DATA = response.data;
      // console.log({ "DATA.DATA": DATA });
      if (DATA.success === true) {
        const actualData: Array<any> = DATA.data;
        data = actualData.map(_data => formater(JSON.stringify(_data)));
        // console.log({ "GET_GenericGender-data.data": data });
      }
      // console.log({ "GET_GenericGender-DATA.data": DATA.data });
      if (data !== null) {
        // @ts-ignore
        state.data.setData(data);
      }
      state.fetched.setDataFetched(true)
      // console.log({ "this.state.data": this.state.data });
    })
    .catch(function (error) {
      console.log(error);
    });
}