import axios from 'axios';
import { CONSTANTS } from "../../../constants";

export async function POST_QuickLogin<Type>(
  accountId: number,
  formater: (json: string) => Type,) {
  const axiosConfig = {
    method: 'post',
    url: CONSTANTS.URL_CONSTANTS.API_BASE_URL + 'clients/hash-hash',
    headers: {
      'content-Type': 'application/json',
      "Cache-Control": "no-cache",
      "Pragma": "no-cache",
      "Expired": "0",
      "Cookie": document.cookie
    },
    data: JSON.stringify({ accountId })
  };
  return await axios(axiosConfig).then((response) => {
    var data: Type | null = null;
    const DATA = response.data;
    // console.log({ "DATA": DATA });

    const stringedData = JSON.stringify(DATA);
    data = formater(stringedData);
    // console.log({ "POST_QuickLogin-data.data": data });
    localStorage.setItem(CONSTANTS.LOCAL_STORAGE.TOKEN, stringedData);
    // console.log({ "POST_QuickLogin-DATA.stringedData": stringedData });
    return data;
  }).catch((err) => {
    // console.log({ "POST_QuickLogin-err": err });
    throw err.response.data;

  });
}