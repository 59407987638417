import { useEffect, useState } from 'react';
import { SelectFormInput } from '../SelectFormInput';
import { SelectOptionsInterface2 as SelectOptionsInterface } from '../../../../../utils/interfaces/views/form/inputs/select';
import { GET_ClientSubGroup } from '../../../../../utils/network/client/group_subgroups/subgroups/get';
import { SubGroupGroupSubgroupViewModel, Convert as sgsConvert } from '../../../../../utils/interfaces/client/group_subgroup/subgroups';

export const SubGroupSelectFormInput = (properties: {
  label: string; name?: string; id?: string; required?: boolean;
  data?: Array<SelectOptionsInterface>, multiple?: boolean;
  selectedValues?: Array<SelectOptionsInterface>,
  setSelectedValues?: React.Dispatch<React.SetStateAction<Array<SelectOptionsInterface>>>
  selectedBranchValues?: Array<SelectOptionsInterface>,
}) => {
  const [_selectedValues, _setSelectedValues] = useState<Array<SelectOptionsInterface>>([{label: "", value: "0"}])
  const label = properties.label,
    id = properties.id ?? "",
    name = properties.name ?? "",
    multiple = properties.multiple ?? false,
    required = properties.required ?? false,
    selectedValues = properties.selectedValues ?? _selectedValues,
    setSelectedValues = properties.setSelectedValues ?? _setSelectedValues,
    selectedBranchValues = properties.selectedBranchValues ?? null,
    data = properties.data ?? null;
  const [subGroups, setGenders] = useState<Array<SubGroupGroupSubgroupViewModel>>([]);
  const [dataFetched, setDataFetched] = useState<boolean>(false);
  const [selectedBranchId, setSelectedBranchId] = useState<number | null>(null);

  useEffect(() => {
    // console.log({ dataFetched, subGroups,  });
    // if (data === null) {
    //   if (!dataFetched) {
    //     GET_ClientSubGroup<SubGroupGroupSubgroupViewModel>(null, null, {
    //       fetched: { dataFetched, setDataFetched }, data: {
    //         data: subGroups, setData: setGenders
    //       }
    //     }, sgsConvert.toSubGroupGroupSubgroupViewModel).then((response) => {
    //       console.log({ response })
    //     })
    //   }
    // }

    const branchId = Array.isArray(selectedBranchValues) && (selectedBranchValues.length > 0)
      ? Number(selectedBranchValues[0].value) : null;
    if (branchId !== selectedBranchId) {
      setSelectedBranchId(branchId);
      GET_ClientSubGroup<SubGroupGroupSubgroupViewModel>(null, branchId, {
        fetched: { dataFetched, setDataFetched }, data: {
          data: subGroups, setData: setGenders
        }
      }, sgsConvert.toSubGroupGroupSubgroupViewModel).then((response) => {
        // console.log({ response })
      })
    }

  }, [dataFetched, subGroups, data, selectedBranchValues, selectedBranchId])

  return (
    <div id="select">
      <SelectFormInput
        id={id} name={name} label={label}
        data={data === null ? subGroups.map(subGroup => {
          return {
            label: String(`${subGroup.groupID?.group ?? "_"} => ${subGroup.subgroup ?? "-"}`),
            value: String(subGroup.id ?? ""),
          }
        }) : data}
        required={required}
        multiple={multiple}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        validation_identifier="Sub-Group(s)" />
    </div>
  );
}