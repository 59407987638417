import { Pagination, Spinner, Tabs } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { HiUserCircle } from 'react-icons/hi';
import { MdMap, MdSecurity, MdFilterList, MdFilterListAlt } from 'react-icons/md';
import { useSearchParams } from 'react-router-dom';
import { NavbarComponent } from '../../components/navbar/NavbarComponent';
import { CONSTANTS } from '../../utils/constants';
import { AccountCodeLogin } from '../../utils/functions/code_login';
import {
  CodeClientViewModel, Convert as ccvmConvert
} from '../../utils/interfaces/client/code';
import {
  IndividualUserRegistrationViewModel, Convert as iurConvert
} from '../../utils/interfaces/registration/users/individuals';
import { POST_IndividualUserRegistration } from '../../utils/network/registration/users/individuals';
import { BioData } from './BioData';
import { Grouping } from './Grouping';
import { Password } from './Password';
import { Residence } from './Residence';
import { Status } from './Status';

export const IndividualView = () => {
  const [clientCode, setClientCode] = useState<CodeClientViewModel | null>(null),
    [paginationCurrentNumber, setPaginationCurrentNumber] = useState<number>(1),
    [loggingIn, setLoggingIn] = useState<boolean>(true),
    [searchParams] = useSearchParams(),
    accountCode = searchParams.get("code"),
    processLocalData = (localData: string) => {
      // console.log({ localData })
      const cC = ccvmConvert.toCodeClientViewModel(localData);
      // console.log({ cC })
      setClientCode(cC);
    };

  useEffect(() => {
    AccountCodeLogin(CONSTANTS.URL_CONSTANTS.PAGE_URLS.INDIVIDUAL,
      accountCode, processLocalData,).then(() => setLoggingIn(false))
      .catch(() => setLoggingIn(false));
    ActiveTabClicked();

  }, [accountCode]);

  const setTabActive = (id: number) => {
    return paginationCurrentNumber === 1 ? true : false;
  }

  const ActiveTabClicked = () => {
    const buttons = document.querySelectorAll('[aria-label="individual-form-view"][role="tablist"] > button');
    buttons.forEach((button, i) => {
      button.addEventListener('click', (e) => {
        setPaginationCurrentNumber(i + 1)
      })
    })
  }

  const changeActiveTab = (id: number) => {
    const buttons = document.querySelectorAll('[aria-label="individual-form-view"][role="tablist"] > button');
    buttons.forEach((button, i) => {
      if ((i + 1) === id) {
        // console.log({ button })
        // @ts-ignore
        button.click()
      }
    })
  }

  const paginationSectionOnChange = (_: number) => {
    if (_ === 1) {
      changeActiveTab(1);
    }
    if (_ === 2) {
      changeActiveTab(2);
    }
    if (_ === 3) {
      changeActiveTab(3);
    }
    if (_ === 4) {
      changeActiveTab(4);
    }
    if (_ === 5) {
      changeActiveTab(5);
    }
    return setPaginationCurrentNumber(_);
  }


  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    POST_IndividualUserRegistration<IndividualUserRegistrationViewModel>(iurConvert.toIndividualUserRegistrationViewModel)
  }

  return (
    <>
      {
        loggingIn ?
          <div className='p-3 pb-[10vh] text-black dark:text-white ' id="individual-form-view">
            <div className='min-h-[75vh] flex justify-center items-center'>
              <Spinner color="warning" size="lg" />
            </div>
          </div> :
          <>
            <NavbarComponent individual={true} organization={false} />
            <h4 className="py-5 flex self-center justify-center whitespace-nowrap text-base font-semibold dark:text-white underline underline-offset-8 uppercase">
              Register As Member
            </h4>
            <div className='p-3 pb-[10vh] text-black dark:text-white ' id="individual-form-view">
              {/* IndividualView = {String(clientCode)} */}
              {/* {paginationCurrentNumber === 1 ? "true" : "false"}
              {paginationCurrentNumber === 2 ? "true" : "false"}
              {paginationCurrentNumber === 3 ? "true" : "false"}
              {paginationCurrentNumber === 4 ? "true" : "false"}
              {paginationCurrentNumber === 5 ? "true" : "false"} */}
              {/* {paginationCurrentNumber} */}
              <form onSubmit={handleFormSubmit} method="post" action="#"
                className='mt-0 px-0 xl:px-44' make-general-posts="individual-user-registration" encType='multipart/form-data'>
                <input type="hidden" name='clientId' value={clientCode?.clientID!} />
                <div className='min-h-full lg:min-h-[60vh]'>
                  <Tabs.Group
                    aria-label="individual-form-view"
                    style={"underline"} >
                    <Tabs.Item
                      active={setTabActive(1)}
                      title="Bio Data"
                      icon={HiUserCircle} >
                      {/* {paginationCurrentNumber} */}
                      <BioData />
                    </Tabs.Item>
                    <Tabs.Item
                      active={setTabActive(2)}
                      title="Groupings"
                      icon={MdFilterListAlt} >
                      <Grouping />
                    </Tabs.Item>
                    <Tabs.Item
                      active={setTabActive(3)}
                      title="Location"
                      icon={MdMap} >
                      <Residence />
                    </Tabs.Item>
                    <Tabs.Item
                      active={setTabActive(4)}
                      title="Status"
                      icon={MdFilterList} >
                      <Status />
                    </Tabs.Item>
                    <Tabs.Item
                      active={setTabActive(5)}
                      title="Password"
                      icon={MdSecurity}>
                      <Password />
                    </Tabs.Item>
                  </Tabs.Group>
                </div>
              </form>
              <div className="flex items-center justify-center text-center border-y border-gray-200 dark:border-gray-700 pt-3 pb-5">
                <div className='hidden md:block'>
                  <Pagination
                    currentPage={paginationCurrentNumber}
                    layout="pagination"
                    onPageChange={paginationSectionOnChange}
                    showIcons={true}
                    totalPages={5} />
                </div>
                <div className='block md:hidden'>
                  <Pagination
                    currentPage={paginationCurrentNumber}
                    layout="navigation"
                    onPageChange={paginationSectionOnChange}
                    showIcons={true}
                    totalPages={5} />
                </div>
              </div>
            </div>
          </>
      }
    </>
  )
}
