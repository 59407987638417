import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CONSTANTS } from '../../../../constants';
import { ValidationIdentifier } from '../../../../functions/validation_identifier';

export async function POST_IndividualUserRegistration<Type>(
  formater: (json: string) => Type,) {
  const formContainer: HTMLFormElement | null = document.querySelector('[make-general-posts="individual-user-registration"]'),
    formData = new FormData(formContainer!);
  // console.log({ formData, formContainer });
  const axiosConfig = {
    method: 'post',
    url: CONSTANTS.URL_CONSTANTS.API_BASE_URL + 'members/user/app-register',
    headers: {
      'content-Type': 'application/json',
      "Cache-Control": "no-cache",
      "Pragma": "no-cache",
      "Expired": "0",
      "Cookie": document.cookie
    },
    data: formData
  };

  const MySwal = withReactContent(Swal)
  MySwal.fire({
    title: "<p>Submit Form?</p>",
    icon: "question",
    showCancelButton: true,
    showCloseButton: true,
    cancelButtonText: "Cancel",
    reverseButtons: true,
    showLoaderOnConfirm: true,
    backdrop: true,
    preConfirm: async (submit) => {
      // console.log({ submit });
      return await axios(axiosConfig).then((response) => {

        console.log({ response });
        var data: Type | null = null;
        const DATA = response.data;
        // console.log({ "DATA": DATA });

        const stringedData = JSON.stringify(DATA);
        data = formater(stringedData);

        const MySwal3 = withReactContent(Swal)

        MySwal3.fire({
          title: "Form Submitted Successfully",
          icon: "success",
        }).then(() => {
          window.location.reload();
        });
        return data;
      }).catch(error => {
        // console.log({ "POST_OrganizationUserRegistration-err": err, "err.response": err.response });
        const data = error.response.data;
        let infos = `<style>#swal_error_content br {display: none !important;}</style><div id="swal_error_content">`;
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            const element: Array<string> = data[key];
            const infoLi = element.map(li => {
              return `<li class="text-sm">${li}</li>`;
            }).toString();
            // ${key}
            let info = `<div>
            <h4 class="whitespace-nowrap text-lg font-semibold uppercase">${ValidationIdentifier(key).identifier}</h4>
            <ul> ${infoLi} </ul>
          </div>`;
            infos += info;
          }
        }
        infos += "</div>"

        const MySwal2 = withReactContent(Swal)

        MySwal2.fire({
          title: infos,
          icon: "error",
        });
        throw error.response.data;
      });
    },
    allowOutsideClick: () => !Swal.isLoading()
  }).then((result) => {
    if (result.isConfirmed) {
      
    }
  });
}