import { useEffect, useState } from 'react';
import { SelectFormInput } from '../SelectFormInput';
import { GET_GenericGender } from '../../../../../utils/network/generic/gender/get'
import { GenderGenericViewModel, Convert as ggConvert } from '../../../../../utils/interfaces/generic/gender';
import { SelectOptionsInterface2 as SelectOptionsInterface } from '../../../../../utils/interfaces/views/form/inputs/select';
// const sdk: <DataType, Dt>() => number = () => {
//   return 0;
// };

export const SelectGenderFormInput = (properties: {
  label: string; name?: string; id?: string; required?: boolean;
  data?: Array<SelectOptionsInterface>, multiple?: boolean;
  selectedValues?: Array<SelectOptionsInterface>,
  setSelectedValues?: React.Dispatch<React.SetStateAction<Array<SelectOptionsInterface>>>
}) => {
  const [_selectedValues, _setSelectedValues] = useState<Array<SelectOptionsInterface>>([{label: "", value: "0"}])
  const label = properties.label,
    id = properties.id ?? "",
    name = properties.name ?? "",
    multiple = properties.multiple ?? false,
    required = properties.required ?? false,
    selectedValues = properties.selectedValues ?? _selectedValues,
    setSelectedValues = properties.setSelectedValues ?? _setSelectedValues,
    data = properties.data ?? null;
  const [genders, setGenders] = useState<Array<GenderGenericViewModel>>([]);
  const [dataFetched, setDataFetched] = useState<boolean>(false);

  useEffect(() => {
    // console.log({ dataFetched, genders });
    if (data === null) {
      if (!dataFetched) {
        GET_GenericGender<GenderGenericViewModel>(null, {
          fetched: { dataFetched, setDataFetched }, data: {
            data: genders, setData: setGenders
          }
        }, ggConvert.toGenderGenericViewModel).then((response) => {
          // console.log({ response })
        })
      }
    }

  }, [dataFetched, genders, data])

  // await GET_GenericGender<GenderGenericViewModel>(null, {
  //   {dataFetched, setDataFetched},
  // }, ggConvert.toGenderGenericViewModel);

  return (
    <div id="select">
      <SelectFormInput
        id={id} name={name} label={label}
        data={data === null ? genders.map(gender => {
          return {
            label: String(gender.name ?? ""),
            value: String(gender.id ?? ""),
            // id: String(gender.id),
            // value: gender.name ?? "", selected: false
          }
        }) : data}
        required={required}
        multiple={multiple}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        validation_identifier="Gender" />
    </div>
  );
}