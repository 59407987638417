import { useEffect, useState } from 'react';
import { SelectFormInput } from '../../SelectFormInput';
import { SelectOptionsInterface2 as SelectOptionsInterface } from '../../../../../../utils/interfaces/views/form/inputs/select';
import { ConstituencyLocationViewModel, Convert as clConvert } from '../../../../../../utils/interfaces/location/constituency';
import { GET_LocationConstituency } from '../../../../../../utils/network/location/constituency/get';

export const ConstituencySelectFormInput = (properties: {
  label: string; name?: string; id?: string; required?: boolean;
  data?: Array<SelectOptionsInterface>, multiple?: boolean;
  selectedValues?: Array<SelectOptionsInterface>,
  setSelectedValues?: React.Dispatch<React.SetStateAction<Array<SelectOptionsInterface>>>,
  selectedRegionValues?: Array<SelectOptionsInterface>,
  selectedDistrictValues?: Array<SelectOptionsInterface>,
}) => {
  const [_selectedValues, _setSelectedValues] = useState<Array<SelectOptionsInterface>>([{label: "", value: "0"}])
  const label = properties.label,
    id = properties.id ?? "",
    name = properties.name ?? "",
    multiple = properties.multiple ?? false,
    required = properties.required ?? false,
    selectedValues = properties.selectedValues ?? _selectedValues,
    setSelectedValues = properties.setSelectedValues ?? _setSelectedValues,
    selectedRegionValues = properties.selectedRegionValues ?? null,
    selectedDistrictValues = properties.selectedDistrictValues ?? null,
    data = properties.data ?? null;
  const [constituencies, setGenders] = useState<Array<ConstituencyLocationViewModel>>([]);
  const [dataFetched, setDataFetched] = useState<boolean>(false);
  const [selectedRegionId, setSelectedRegionId] = useState<number>(0);
  const [selectedDistrictId, setSelectedDistrictId] = useState<number>(0);

  useEffect(() => {
    // console.log({ dataFetched, constituencies });
    // if (data === null) {
    //   if (!dataFetched) {
    //     GET_LocationConstituency<ConstituencyLocationViewModel>(null, {
    //       fetched: { dataFetched, setDataFetched }, data: {
    //         data: constituencies, setData: setGenders
    //       }
    //     }, clConvert.toConstituencyLocationViewModel).then((response) => {
    //       console.log({ response })
    //     })
    //   }
    // }

    const regionId = Array.isArray(selectedRegionValues) && (selectedRegionValues.length > 0)
      ? Number(selectedRegionValues[0].value) : 0;
    const districtId = Array.isArray(selectedDistrictValues) && (selectedDistrictValues.length > 0)
      ? Number(selectedDistrictValues[0].value) : 0;
    if (districtId !== selectedDistrictId) {
      setSelectedRegionId(regionId);
      setSelectedDistrictId(districtId);
      GET_LocationConstituency<ConstituencyLocationViewModel>(regionId, districtId, {
        fetched: { dataFetched, setDataFetched }, data: {
          data: constituencies, setData: setGenders
        }
      }, clConvert.toConstituencyLocationViewModel).then((response) => {
        // console.log({ response })
      })
    }

  }, [dataFetched, constituencies, data, selectedDistrictId, selectedDistrictValues, selectedRegionId, selectedRegionValues])

  const selectOptions = () => {
    let selectOptions: Array<ConstituencyLocationViewModel> = [{ location: "Select Constituency", id: 0 }];
    return [...selectOptions, ...constituencies]
  }
  return (
    <div id="select">
      <SelectFormInput
        id={id} name={name} label={label}
        data={data === null ? selectOptions().map(constituency => {
          return {
            label: String(constituency.location ?? ""),
            value: String(constituency.id ?? ""),
          }
        }) : data}
        required={required}
        multiple={multiple}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        validation_identifier="Constituency" />
    </div>
  );
}