import { Label, TextInput, Textarea } from "flowbite-react";

export const Status = () => {
  
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 grid-flow-row gap-4 pb-5'>
      <div className='p-2'>
        <div className="mb-2 block">
          <Label
            htmlFor="website"
            value="Enter Organization's Website Address if any"
            data-validation-identifier={`{ "id": "website", "identifier": "Website" }`} />
        </div>
        <TextInput
          id="website"
          name='website'
          type="url"
          placeholder="Organization's Website Address if any"
          required={false} />
      </div>
      <div className='p-2'>
        <div className="mb-2 block">
          <Label
            htmlFor="businessDescription"
            value="Enter Organization's Core Areas of Operation [ Maximum 500 characters ]"
            data-validation-identifier={`{ "id": "businessDescription", "identifier": "Business Description" }`} />
        </div>
        <Textarea
          id="businessDescription"
          name='businessDescription'
          rows={6} maxLength={550}
          placeholder="Business description"
          required={false} />
      </div>
    </div>
  )
}
